import { useEffect, useState } from "react";
import { getPromoCode } from "../../../../services";
import { createRequestState } from "../../../../utils";
import {
  EnergyCalculatorFormValues,
  PromoCode,
  RequestState,
} from "../../../../types";

export const usePromoCode = (
  promoCode: string,
  triggerValidation: (payload: Array<keyof EnergyCalculatorFormValues>) => void
): RequestState<PromoCode> => {
  const [promoCodeData, setPromoCode] = useState(
    createRequestState<PromoCode>()
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setPromoCode({ isPending: true, data: null, error: null });
        const promise = getPromoCode(promoCode);
        const data = await promise;
        setPromoCode({ isPending: false, data, error: null });
        triggerValidation(["promoCode"]);
      } catch (error) {
        setPromoCode({ isPending: false, data: null, error });
        triggerValidation(["promoCode"]);
      }
    };
    if (promoCode && promoCode.length) {
      fetchData();
    }
  }, [promoCode, triggerValidation]);
  return promoCodeData;
};
