import React, { ReactElement, RefObject } from "react";
import cn from "classnames";

import {
  InputBaseProps,
  RequestState,
  Street,
  EnergyCalculatorFormValues,
} from "../../../types";

import Input from "../Input";

import styles from "./styles.mod.scss";
import { useAutocomplete } from "../../../hooks/useAutocomplete";
interface InputStreetProps extends InputBaseProps {
  streets: RequestState<Street[]>;
  street: string;
  setStreet: (
    values: Record<string, unknown>[],
    shouldValidate?: boolean
  ) => void;
  triggerValidation: (payload: keyof EnergyCalculatorFormValues) => void;
  onStreetChange: () => void;
}

const InputStreet = ({
  streets: { isPending, data },
  street,
  setStreet,
  triggerValidation,
  onStreetChange,
  ...rest
}: InputStreetProps): ReactElement => {
  const handleAutocompleteItemSelection = () => {
    if (!autocomplete?.length) {
      return;
    }

    setStreet([{ street: autocomplete[cursor]?.name }]);
    setIsOpen(false);
    triggerValidation("street");
  };

  const filterStreets = (streets: Street[] | null, term: string) => {
    return streets?.filter((street) =>
      street.name
        .toLocaleLowerCase()
        .replace(/\s/g, "")
        .includes(term.toLocaleLowerCase().replace(/\s/g, ""))
    );
  };

  const autocomplete = filterStreets(data, street);

  const {
    setIsOpen,
    wrapperRef,
    isOpen,
    cursor,
    setCursor,
  } = useAutocomplete();

  return (
    <div
      className={styles.autocomplete}
      ref={wrapperRef as RefObject<HTMLDivElement>}
    >
      <Input
        onFocus={() => setIsOpen(true)}
        onChange={() => {
          if (!isOpen) {
            setIsOpen(true);
          }
          onStreetChange();
        }}
        {...rest}
      />
      {!isPending &&
        autocomplete &&
        Boolean(autocomplete?.length) &&
        isOpen &&
        street.length >= 1 && (
          <div className={styles.autocomplete__options}>
            <ul className={cn(styles.autocomplete__list, "scrollbar")}>
              {autocomplete.map((street, i) => (
                <li
                  className={cn(styles.autocomplete__listItem, {
                    [styles.autocomplete__listItem_hovered]: cursor === i,
                  })}
                  key={street.name}
                  onClick={handleAutocompleteItemSelection}
                  onMouseEnter={() => setCursor(i)}
                  tabIndex={0}
                >
                  {street.name}
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};

export default InputStreet;
