import { Validate, ValidateResult } from "react-hook-form";

import { City, Street } from "../../types";
import { getAddressValidation } from "../../services";

export const postCodeReg = /^[0-9]{5}$/;

export const validatePostcode = (
  citiesPromise: Promise<City[]> | undefined
): Record<string, Validate> => {
  return {
    match: (value: string) => {
      const isValid = postCodeReg.test(value);
      if (isValid) {
        return true;
      }

      return "Bitte gib eine existierende, 5-stellige Postleitzahl ein.";
    },
    async: async (value: string) => {
      const cities = await citiesPromise;
      const isValid = cities?.some(
        (city) => city.postcode.toString() === value
      );
      if (isValid) {
        return true;
      }

      return "Bitte gib eine existierende, 5-stellige Postleitzahl ein.";
    },
  };
};

export const validateCity = {
  required: (value: string): ValidateResult => {
    if (value.length > 0) {
      return true;
    }

    return "Bitte gib eine existierende, 5-stellige Postleitzahl ein.";
  },
};

export const validateStreet = (
  streetsPromise: Promise<Street[]> | undefined,
  isRequired = false
): Record<string, Validate> => {
  return {
    match: (value: string) => {
      if (!isRequired && value.length === 0) {
        return true;
      }

      const isValid = /^[a-zA-ZßüÜöÖäÄ0-9][ a-zA-ZßüÜöÖäÄ0-9\-'/.]*$/.test(
        value
      );

      if (isValid) {
        return true;
      }

      return "Bitte gib einen gültigen Straßennamen ein.";
    },
    async: async (value: string) => {
      if (!isRequired && value.length === 0) {
        return true;
      }

      const streets = await streetsPromise;
      const isValid = streets?.some(
        (street) => street.name.toLowerCase() === value.toLowerCase()
      );
      if (isValid) {
        return true;
      }

      return "Bitte gib einen gültigen Straßennamen ein.";
    },
  };
};

export const validateHouseNumber = (
  cityId: number,
  streetName: string,
  isRequired = false
): Record<string, Validate> => {
  return {
    match: (value: string) => {
      if (!isRequired && value.length === 0) {
        return true;
      }

      const isValid =
        /^[^- A-Za-z\-'/.](?!.* {2})[A-Za-z0-9 \-'/.]{1,6}$/.test(value) ||
        /^[0-9]{1,6}$/.test(value);
      if (isValid) {
        return true;
      }

      return "Ungültige Nr.";
    },
    async: async (value: string) => {
      if (!isRequired && value.length === 0) {
        return true;
      }
      if (cityId) {
        try {
          const { status } = await getAddressValidation({
            cityId,
            streetName,
            houseNumber: value,
          });
          if (status) {
            return true;
          }

          return "Ungültige Nr.";
        } catch (error) {
          return "Ungültige Nr.";
        }
      }
    },
  };
};

export const validateEnergyUsage = {
  match: (value: string): ValidateResult => {
    const isValid = /^[0-9]+$/.test(value);
    if (isValid || value.length === 0) {
      return true;
    }

    return "Dein Energieverbrauch sollte zwischen 1 kWh und 50.000 kWh liegen.";
  },
  range: (value: string): ValidateResult => {
    const usage = Number(value);
    if (usage >= 1 && usage <= 50000) {
      return true;
    }

    return "Dein Energieverbrauch sollte zwischen 1 kWh und 50.000 kWh liegen.";
  },
};
