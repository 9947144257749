import React, {
  ReactElement,
  useEffect,
  SyntheticEvent,
  RefObject,
} from "react";
import cn from "classnames";

import { InputBaseProps, RequestState, City } from "../../../types";

import Input from "../Input";

import styles from "./styles.mod.scss";
import { useAutocomplete } from "../../../hooks/useAutocomplete";
interface InputCityProps extends InputBaseProps {
  cities: RequestState<City[]>;
  setCity: (
    values: Record<string, unknown>[],
    shouldValidate?: boolean
  ) => void;
  cityId: string;
}

const InputCity = ({
  cities: { isPending, data },
  setCity,
  cityId,
  ...rest
}: InputCityProps): ReactElement => {
  const setCityValue = (city: City) => {
    setCity([{ postalCode: city.postcode }, { cityId: city.id }], true);
    setIsOpen(false);
  };
  useEffect(() => {
    if (data?.length === 1) {
      setCityValue(data[0]);
    }
  }, [data]);

  const handleAutocompleteItemSelection = () => {
    if (!data?.length) {
      return;
    }

    setCity(
      [{ cityId: data[cursor]?.id }, { postalCode: data[cursor]?.postcode }],
      true
    );
    setIsOpen(false);
  };

  const getCityName = (cities: City[] | null, cityId: string): string => {
    let result = "";

    if (!cities) {
      setCity([{ cityId: "" }]);

      return result;
    }

    cities.forEach((city) => {
      if (Number(city.id) === Number(cityId)) {
        result = city.name;
      }
    });

    return result;
  };

  const {
    setIsOpen,
    wrapperRef,
    isOpen,
    cursor,
    setCursor,
  } = useAutocomplete();

  return (
    <div
      className={styles.autocomplete}
      ref={wrapperRef as RefObject<HTMLDivElement>}
    >
      <Input
        onFocus={() => setIsOpen(true)}
        onChange={(event: SyntheticEvent<EventTarget>) => {
          const { value } = event.target as HTMLInputElement;
          if (!value.length) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        }}
        inputClassName={styles.autocomplete__input}
        {...rest}
      />
      {cityId && (
        <div className={styles.autocomplete__city}>
          {getCityName(data, cityId)}
        </div>
      )}
      {!isPending && Boolean(data?.length) && isOpen && (
        <div className={styles.autocomplete__options}>
          <ul className={cn(styles.autocomplete__list, "scrollbar")}>
            {data?.map((city, i) => (
              <li
                key={city.id}
                className={styles.autocomplete__listItem}
                onClick={handleAutocompleteItemSelection}
                onMouseEnter={() => setCursor(i)}
                tabIndex={0}
              >
                <span
                  className={cn(styles.autocomplete__itemTitle, {
                    [styles.autocomplete__itemTitle_hovered]: cursor === i,
                  })}
                >
                  {city.postcode}
                </span>
                <span className={styles.autocomplete__itemSubTitle}>
                  {city.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InputCity;
