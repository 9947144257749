import { MutableRefObject, useEffect, useRef, useState } from "react";
interface AutocompleteApi {
  wrapperRef: MutableRefObject<Element | null>;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  cursor: number;
  setCursor: (nextCursor: number) => void;
}

const useClickOutside = ({
  onOutsideClick,
}: {
  onOutsideClick: () => void;
}) => {
  const wrapperRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    } else {
      const handleClickOutside = (event: MouseEvent) => {
        if (!wrapperRef.current?.contains(event.target as Node)) {
          onOutsideClick();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [wrapperRef]);

  return wrapperRef;
};

export const useAutocomplete = (): AutocompleteApi => {
  const [isOpen, setIsOpen] = useState(false);
  const [cursor, setCursor] = useState<number>(0);

  const wrapperRef = useClickOutside({
    onOutsideClick: () => setIsOpen(false),
  });

  return {
    wrapperRef,
    setIsOpen,
    isOpen,
    cursor,
    setCursor,
  };
};
