import React, { ReactElement } from "react";

import { InputBaseProps } from "../../../types";

import Input from "../Input";
import { PersonSVG } from "../../SVG";

import styles from "./styles.mod.scss";

interface RangeProps extends InputBaseProps {
  value: number;
  min: number;
  max: number;
}

const rangeTheme = {
  fill: "#526df2",
  background: "#ecf0f1",
  svgDefault: "#D5DBDD",
  svgActive: "#526DF2",
};

const Range = ({
  id,
  label,
  value,
  min,
  max,
  ...rest
}: RangeProps): ReactElement => {
  const percentage = (100 * (value - min)) / (max - min);
  const range = Array.from({ length: max });

  return (
    <div className={styles.range}>
      <Input
        id={id}
        inputClassName={styles.range__element}
        label={label}
        min={min}
        max={max}
        style={{
          background: `linear-gradient(90deg, ${rangeTheme.fill} ${percentage}%, ${rangeTheme.background} ${percentage}%)`,
        }}
        {...rest}
        type="range"
      />
      <div className={styles.range__persons}>
        {range.map((item, index) => (
          <PersonSVG
            key={index}
            fill={index >= value ? rangeTheme.svgDefault : rangeTheme.svgActive}
          />
        ))}
      </div>
    </div>
  );
};

export default Range;
