import React, { ReactElement } from "react";
import cn from "classnames";

import { InputBaseProps } from "../../../types";

import styles from "./styles.mod.scss";

const InputBase = ({
  id,
  type,
  label,
  error,
  inputRef,
  inputClassName,
  disabled,
  full,
  ...inputAttributes
}: InputBaseProps): ReactElement => {
  return (
    <div className={`${styles.input} ${full ? styles.full : ""}`}>
      <label
        className={cn(styles.input__label, {
          [styles["input__label_disabled"]]: disabled,
        })}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        className={cn(styles.input__element, {
          [styles["input__element_error"]]: error,
          ...(inputClassName && { [inputClassName]: true }),
        })}
        id={id}
        type={type}
        ref={inputRef}
        disabled={disabled}
        {...inputAttributes}
      />
      {error && <div className={styles.input__error}>{error}</div>}
    </div>
  );
};

export default InputBase;
