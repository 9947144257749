import { useState, useEffect, SyntheticEvent } from "react";
import { useForm } from "react-hook-form";

import { createRequestState } from "../../../utils";
import {
  City,
  Street,
  EnergyCalculatorFormValues,
  RequestState,
  EnergyTariffValues,
  SetState,
} from "../../../types";
import { getCities, getStreets, getTariff } from "../../../services";
import { postCodeReg } from "../validation";
import { setCitiesState, setIsPreloader } from "../../../store/actions";
import { useDispatch } from "react-redux";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTariff = (
  setFormValues?: SetState<RequestState<EnergyTariffValues>>,
  initValues?: EnergyCalculatorFormValues
) => {
  const [citiesPromise, setCitiesPromise] = useState<Promise<City[]>>();
  const [cities, setCities] = useState(createRequestState<City[]>());
  const [streetsPromise, setStreetsPromise] = useState<Promise<Street[]>>();
  const [streets, setStreets] = useState(createRequestState<Street[]>());
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    triggerValidation,
    clearError,
    formState,
    getValues,
    reset,
  } = useForm<EnergyCalculatorFormValues>({
    mode: "onBlur",
    defaultValues: {
      postalCode: initValues?.postalCode || "",
      cityId: initValues?.cityId || "",
      cityName: initValues?.cityName || "",
      street: initValues?.street || "",
      houseNumber: initValues?.houseNumber || "",
      energyUsage: initValues?.energyUsage || "2500",
      energyUsageRange: initValues?.energyUsageRange || "2",
      promoCode: initValues?.promoCode || "",
      promoCodeDescription: initValues?.promoCodeDescription || "",
    },
  });

  const { energyUsageRange, postalCode, cityId, street, promoCode } = watch([
    "energyUsageRange",
    "postalCode",
    "cityId",
    "street",
    "promoCode",
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCities({ isPending: true, data: null, error: null });
        const promise = getCities({ postalCode });

        setCitiesPromise(promise);

        const data = (await promise).map((city) => {
          if (String(city.postcode).length === 4) {
            city.postcode = `0${city.postcode}`;
          }
          return city;
        });
        setCities({ isPending: false, data, error: null });
      } catch (error) {
        setCities({ isPending: false, data: null, error });
      }
    };
    if (postCodeReg.test(postalCode)) {
      fetchData();
    } else {
      setValue([{ cityId: "" }]);
    }
  }, [postalCode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStreets({ isPending: true, data: null, error: null });
        const promise = getStreets({
          postcode: postalCode,
        });

        setStreetsPromise(promise);

        const data = await promise;
        setStreets({ isPending: false, data, error: null });
      } catch (error) {
        setStreets({ isPending: false, data: null, error });
      }
    };

    if (postCodeReg.test(postalCode)) {
      fetchData();
    }
  }, [postalCode]);

  const setValues = (
    values: Record<string, unknown>[],
    shouldValidate?: boolean
  ) => {
    setValue(values, shouldValidate);
  };

  const handlePromoCode = (value: string) => {
    setValue("promoCode", value);
  };

  const handleEnergyUsage = (event: SyntheticEvent<EventTarget>) => {
    const { value } = event.target as HTMLInputElement;
    let range = "1";

    const number = Number(value);

    if (number <= 1500) {
      range = "1";
    } else if (number <= 2500) {
      range = "2";
    } else if (number <= 3500) {
      range = "3";
    } else if (number <= 4250) {
      range = "4";
    } else if (number > 4250) {
      range = "5";
    }

    setValue("energyUsageRange", range);
  };

  const handleRange = (event: SyntheticEvent<EventTarget>) => {
    const { value } = event.target as HTMLInputElement;
    let energy = "1500";

    switch (value) {
      case "2":
        energy = "2500";
        break;
      case "3":
        energy = "3500";
        break;
      case "4":
        energy = "4250";
        break;
      case "5":
        energy = "5000";
        break;
      default:
        break;
    }

    setValue("energyUsage", energy);
  };

  const onSubmit = async (values: EnergyCalculatorFormValues) => {
    if (setFormValues) {
      try {
        setFormValues({
          isPending: true,
          data: null,
          error: null,
        });
        dispatch(setIsPreloader(true));
        const data = await getTariff({
          usage: values.energyUsage ? values.energyUsage : "",
          cityId: values.cityId ? values.cityId : "",
          ...(values.street && { streetName: values.street }),
          ...(values.houseNumber && { houseNumber: values.houseNumber }),
          ...(values.promoCode && { promoCode: values.promoCode }),
        });

        setFormValues({
          isPending: false,
          data: { tariff: data, formValues: values },
          error: null,
        });
        dispatch(setIsPreloader(false));
      } catch (error) {
        setFormValues({ isPending: false, data: null, error });
        dispatch(setIsPreloader(false));
      }
    }
  };
  useEffect(() => {
    if (postalCode.length !== 5) {
      setValue([{ street: "" }, { houseNumber: "" }]);
      clearError(["street", "houseNumber"]);
    }
  }, [postalCode, setValue]);

  useEffect(() => {
    dispatch(setCitiesState(cities));
  }, [cities]);

  useEffect(() => {
    let id: number;
    if (
      initValues?.cityId ||
      initValues?.street ||
      initValues?.houseNumber ||
      initValues?.energyUsage ||
      initValues?.energyUsageRange
    ) {
      id = setTimeout(() => {
        triggerValidation([
          "postalCode",
          "street",
          "houseNumber",
          "energyUsage",
        ]);
      }, 1000);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [initValues, triggerValidation]);

  return {
    citiesPromise,
    cities,
    promoCode,
    handlePromoCode,
    streetsPromise,
    streets,
    register,
    handleSubmit,
    errors,
    triggerValidation,
    energyUsageRange,
    cityId,
    street,
    setValues,
    clearError,
    handleEnergyUsage,
    handleRange,
    onSubmit,
    formState,
    getValues,
    reset,
    watch,
  };
};
